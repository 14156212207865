import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import { Container, Text } from '../components/elements';
import { PrimaryButton } from '../components/shared/Buttons';

const NotFoundPage = ({ data }) => {
  const {
    prismicLayout: { data: layout }
  } = data;

  return (
    <Layout layout={layout} siteMetadata={data.site.siteMetadata}>
      <Container
        width="100%"
        py={200}
        px="15px"
        style={{ overflow: 'hidden' }}
        textAlign="center"
      >
        <Text as="h1" fontSize={72} mb={4}>
          404
        </Text>
        <Text as="p" mb={4}>
          The page you're looking for does not exist.
        </Text>
        <PrimaryButton to="/">Go to Homepage</PrimaryButton>
      </Container>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default NotFoundPage;

export const query = graphql`
  query {
    ...LayoutFragment
    site {
      siteMetadata {
        title
        author
      }
    }
  }
`;
